import { Grid } from "@mui/material";
import ButtonNewVoice from "../components/importVoicePanel";
import ImportVoicePanel from "../components/importVoicePanel";
import AddVoiceFormDialog from "../components/addVoiceFromDialog";
import { GlobalContext, apiUrl } from "../App";
import React, { useEffect } from "react";

export default function VoiceListTab() {

    const { voicesData, setVoicesData } = React.useContext(GlobalContext);

    useEffect(() => {
        fetch(apiUrl + '/get_all_voices/')
            .then(response => response.json())
            .then(data => {
                console.log('data:', data);
                setVoicesData(data);
            }
            );
    }, []);



    return (
        <Grid container>
            <Grid
                item xs={12}
                padding={5}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                    overflow: 'auto',
                }}
            >
                <h1>Voix</h1>
                <ul>
                    {/* for each elem in voicedata */}

                    {voicesData && voicesData.length > 0 && voicesData.map((voice, index) => (
                        <li>
                            {voice.character} - {voice.emotion} :
                            {voice.method === "xtts" && (
                                <p>
                                    <audio controls>
                                        <source src={apiUrl + voice.extra_data.voice_path} />
                                    </audio>
                                </p>
                            )}
                            {/* if elevenlabs display the name of the character */}
                            {voice.method === "elevenlabs" && (
                                <p>&nbsp;&nbsp;&nbsp;ElevenLabs: <b>{voice.extra_data.eleven_name}</b></p>
                            )}
                        </li>
                    ))}
                </ul>


                <AddVoiceFormDialog />

            </Grid>

        </Grid >

    )
}