import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import img from '../static/logo_blue_spirit.png';

export default function MultiActionAreaCard() {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          src={img}
          alt="Background with shining wave design"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" align="left">
            Bienvenue
          </Typography>
          <Typography variant="body2" color="text.secondary" align="left">
          Découvrez Zéphir, votre compagnon ultime pour l'analyse de scénarios dans l'animation. Consultez notre chatbot pour des réponses instantanées sur les scripts et réalisez leur dépouillement en toute simplicité !
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}