import { useContext } from "react"
import { GlobalContext } from "../App"
import { DataGrid } from '@mui/x-data-grid';
import AddFileFormDialog from "../components/addFileFormDialog";
import TableSource from "../components/tableSources";
import TableSourceV2 from "../components/tableSourcesV2";
import ImportPDFPanel from "../components/importPdfPanel";
import { Grid, IconButton, Box } from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';


export default function GestionPage() {
    const globalContext = useContext(GlobalContext)

    function navigateToBack() {
        console.log("TODO");
    }

    return (
        <Grid container>
            <Grid 
                item xs={12} 
                alignItems="center"
                justifyContent="center"
            >
                <Box sx={{ 
                    borderBottom: 1, 
                    borderColor: 'divider', 
                    backgroundColor: '#fff', 
                    minHeight: '48px', 
                    lineHeight: '48px' }}
                >
                    <IconButton onClick={() => {
                            navigateToBack()
                        }} 
                        sx={{color: '#757575'}}
                        aria-label="Retour"
                        title="Retour"
                    >
                        <KeyboardArrowLeftIcon />
                    </IconButton>
                </Box>
            </Grid>
            
            <Grid 
                item xs={12} 
                padding={5}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{                
                    overflow: 'auto', 
                }}
            >
                {/*<TableSource />*/}
                <TableSourceV2 />
                <AddFileFormDialog />
            </Grid>

        </Grid>
        
    )
}