import { Button, FormControl, FormControlLabel, FormLabel, MenuItem, RadioGroup, Select, Stack, Tab, Tabs, TextField } from "@mui/material";
import React, { Component } from "react";
import Radio from '@mui/material/Radio';
import { a11yProps, CustomTabPanel } from "../pages/SpecifiquePage";
import { apiUrl, GlobalContext } from "../App";
import { AudioRecorder } from 'react-audio-voice-recorder';

class VoiceTip extends Component {

    constructor(props) {
        super(props);
        this.state = {
            compact: true,
            text: props.content.text,
            emoji: "🔥",
            character: "null",
            emotion: "",
            currentTab: 0,
            selectedRVCModel: null,
            audioBlob: null,
        };

    }

    componentDidUpdate(nextProps, nextState) {
        const { onUpdate } = this.props;

        if (onUpdate && this.state.compact !== nextState.compact) {
            onUpdate();
        }
    }



    render() {
        const { onConfirm, onOpen, rvcModels } = this.props;
        const { compact, text, emoji } = this.state;


        return (
            <div className="Tip">
                {compact ? (
                    <div
                        className="Tip__compact"
                        onClick={() => {
                            onOpen();
                            this.setState({ compact: false });
                        }}
                    >
                        Ajouter une voix
                    </div>
                ) : (
                    <form
                        className="Tip__card"
                        onSubmit={(event) => {
                            event.preventDefault();
                            console.log('submit', this.state);
                            onConfirm(
                                this.state.currentTab === 0 ? 'tts' : 'v2v',
                                this.state.character,
                                this.state.emotion,
                                this.state.text,
                                this.state.selectedRVCModel,
                                this.state.audioBlob,
                                { text, emoji });
                        }}
                        style={{
                            paddingTop: "10px",
                            paddingBottom: "10px",
                        }}

                    >

                        <Tabs value={this.state.currentTab} onChange={(e, newValue) => this.setState({ currentTab: newValue })}
                            aria-label="basic tabs example">
                            <Tab label="Text to speech" {...a11yProps(0)} />
                            <Tab label="Voice To Voice" {...a11yProps(1)} />
                        </Tabs>
                        <CustomTabPanel value={this.state.currentTab} index={0}>
                            <Stack direction="row">
                                <FormControl>
                                    <FormLabel>Character</FormLabel>
                                    <RadioGroup onChange={(e) => this.setState({ character: e.target.value })}
                                        aria-label="character" name="character" value={this.state.character}>
                                        {this.props.voicesData.map((voice) => (
                                            <FormControlLabel value={voice.character} control={<Radio />} label={voice.character} />
                                        ))}

                                    </RadioGroup>

                                </FormControl>
                                <FormControl>
                                    <FormLabel>Emotion</FormLabel>
                                    <RadioGroup onChange={(e) => this.setState({ emotion: e.target.value })}
                                        aria-label="emotion" name="emotion" value={this.state.emotion}>
                                        {this.props.voicesData.map((voice) => (
                                            <FormControlLabel value={voice.emotion} control={<Radio />} label={voice.emotion} />
                                        ))}

                                    </RadioGroup>

                                </FormControl>

                                <TextField
                                    label="Text"
                                    value={text}
                                    onChange={(e) => this.setState({ text: e.target.value })}
                                    multiline
                                    rows={2}
                                />


                            </Stack >
                            <Button type="submit" variant="contained">Generate voice</Button>

                        </CustomTabPanel>
                        <CustomTabPanel value={this.state.currentTab} index={1}>

                            {/* <Select label="RVC Model" value={this.state.selectedRVCModel} onChange={(e) => this.setState({ selectedRVCModel: e.target.value })}>
                                {rvcModels.map((model) => (
                                    <MenuItem value={model.id}>{model.name}</MenuItem>
                                ))}
                            </Select> */}
                            <FormControl>
                                <FormLabel>RVC Model</FormLabel>
                                <RadioGroup onChange={(e) => this.setState({ selectedRVCModel: e.target.value })}
                                    aria-label="RVC Model" name="RVC Model" value={this.state.selectedRVCModel}>
                                    {rvcModels.map((model) => (
                                        <FormControlLabel value={model.id} control={<Radio />} label={model.name} />
                                    ))}

                                </RadioGroup>
                            </FormControl>
                            <AudioRecorder
                                onRecordingComplete={(blob) => {
                                    console.log('recording complete', blob);
                                    this.setState({ audioBlob: blob });
                                }
                                }
                                audioTrackConstraints={{
                                    noiseSuppression: true,
                                    echoCancellation: true,
                                }}
                                downloadOnSavePress={false}
                                downloadFileExtension="wav"
                            />

                            <Button type="submit" variant="contained">Generate voice</Button>

                        </CustomTabPanel>



                    </form>
                )
                }
            </div>
        );
    }
}

export default VoiceTip;
