import React from 'react';
import Header from '../components/header';
import HomeCard from '../components/homeCard';
import { Grid, Box, Select, MenuItem } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import Button from '@mui/material/Button';
import { Outlet } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { GlobalContext, apiUrl } from '../App';

export default function Layout() {

    const { specificMode, setSpecificMode } = React.useContext(GlobalContext);
    const [gpuStatus, setGpuStatus] = React.useState('Unknown');

    // Fetch the GPU status every 5 seconds
    React.useEffect(() => {
        const interval = setInterval(() => {
            fetch(apiUrl + '/status_gpu/')
                .then(response => response.json())
                .then(data => {
                    setGpuStatus(data.status);
                });
        }, 5000);
        return () => clearInterval(interval);
    });



    return (
        <Grid container
            sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(12, 1fr)',
                gridTemplateRows: '70px 1fr',
                gridTemplateAreas: `"header header header header header header header header header header header header"
                    "sidebar sidebar sidebar main main main main main main main main main "`,
                maxHeight: '100vh',
                height: '100vh',
            }}
        >
            <Grid container sx={{ gridArea: 'header', bgcolor: 'primary.main' }}>
                <Header />
            </Grid>
            <Grid sx={{ gridArea: 'sidebar', height: '100%' }} component="aside" >
                <Grid container
                    sx={{
                        display: "grid",
                        flex: 1,
                        height: "100%",
                        gridTemplateRows: 'auto auto 60px'
                    }}
                    justifyContent='center'
                    alignItems='space-between'
                >
                    <Grid item sx={{ paddingTop: '1em' }}>
                        <HomeCard />
                    </Grid>
                    <Grid item display='grid' alignItems='flex-start' justifyContent='center'>
                        <NavLink to="/gestion">
                            {({ isActive, isPending, isTransitioning }) => (
                                <Button variant={isActive ? "contained" : "outlined"} endIcon={<SettingsIcon />}>
                                    Gestion des scénarios
                                </Button>
                            )}
                        </NavLink>
                    </Grid>
                    {/* Button to call api /startup_gpu/ */}
                    <Grid item display='grid' alignItems='flex-start' justifyContent='center'>
                        <Button variant="outlined" onClick={() => fetch(apiUrl + '/startup_gpu/')} >
                            Start GPU
                        </Button>
                    </Grid>
                    <Grid item display='grid' alignItems='flex-start' justifyContent='center'>
                        <p>
                            GPU status: {gpuStatus}
                        </p>
                    </Grid>
                    <Grid item display='grid' alignItems='flex-start' justifyContent='center'>
                        <Button variant="outlined" onClick={() => fetch(apiUrl + '/stop_gpu/')} >
                            Stop GPU
                        </Button>
                    </Grid>

                </Grid>
            </Grid>
            <Grid container item xs={12} sx={{
                gridArea: 'main',
                backgroundColor: '#F9F9F9',
                borderLeft: 1,
                borderColor: 'divider',
                maxHeight: '100%',
                overflow: 'auto',
            }}>
                <Outlet sx={{ maxHeight: '100%' }} />
            </Grid>
        </Grid>
    )
}