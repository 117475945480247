import "react-chat-elements/dist/main.css"

import { MessageBox } from "react-chat-elements";
import { Box, Button, CircularProgress, Grid, TextField } from "@mui/material";
import { useContext, useRef, useState } from "react";
import PassagesPanel from "./passagePanel";
import { Avatar } from "react-chat-elements";
import { GlobalContext, apiUrl } from "../App";
import SendIcon from '@mui/icons-material/Send';

const dummy_history = [
    {
        text: "Bonjour, comment puis-je vous aider ?",
        title: "Chatbot"
    }
]

export function Chatbot({ history, setHistory, passages, setPassages, filterShowName, filterSeasonName, filterPdfName }) {

    const { setCurrentHighlightId } = useContext(GlobalContext)

    const [message, setMessage] = useState("")
    const [filters, setFilters] = useState(null)
    const [loading, setLoading] = useState(false)
    let historyRef = useRef(history);

    function handleChat() {
        // TODO: desactivate the button
        const postData = {
            history: history,
            message: message,
            filters: { filter_pdfname: filterPdfName, filter_showname: filterShowName, filter_seasonname: filterSeasonName }
        }

        console.log('postData:', postData)
        setMessage("")
        setLoading(true)
        fetch(apiUrl + 'chat/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                setHistory(prevHistory => [...prevHistory, { text: data.answer, title: "Chatbot" }])
                // setHistory([...history, { text: data.answer, title: "Chatbot" }])

                setPassages(data.passages)
                setLoading(false)
            })
            .catch((error) => {
                console.error('Error:', error);
                setHistory([...history, { text: "Il y a eu une erreur", title: "Chatbot" }])
                setLoading(false)
            });
    }



    return (
        <Grid container xs={12}>
            {/* <Button onClick={() => console.log('history:', history)}>history</Button> */}
            {/* Chat history */}
            <Grid container item xs={6}>
                <Grid container sx={{ maxHeight: '100%', display: "grid", gridTemplateRows: "1fr 56px" }}>
                    <Grid sx={{ p: "8px" }}>
                        {history.map((item, index) => (
                            <MessageBox
                                key={index}
                                position={index % 2 === 0 ? 'left' : 'right'}
                                type={'text'}
                                text={item.text}
                                title={item.title}
                                style={{ textAlign: "start", backgroundColor: index % 2 === 0 ? '#eee' : '#eaf2fb' }}
                            />
                        ))}
                    </Grid>

                    {/* Chat input */}
                    <Grid container
                        columnSpacing={2}
                        sx={{ borderTop: 1, borderColor: 'divider' }}
                        alignItems="center"
                        justifyContent="space-between">
                        <Grid item sx={{ flex: 1 }}>
                            <TextField fullWidth id="fullWidth" value={message} onChange={(e) => setMessage(e.target.value)} />
                        </Grid>
                        <Grid item>

                            <Button variant="contained" endIcon={<SendIcon />} onClick={() => {
                                setHistory(prevHistory => [...prevHistory, { text: message, title: "User" }])
                                // setHistory((prevHistory) => [prevHistory, { text: message, title: "Chatbot" }])
                                handleChat()
                            }}
                                disabled={loading}
                            >
                                {loading ? <CircularProgress /> : "Envoyer"}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item xs={6} sx={{ p: 4, backgroundColor: '#fff' }}>
                {passages.length > 0 &&
                    <PassagesPanel passages={passages} onPassageClick={(passage) => console.log(passage)}
                        setCurrentHighlightId={setCurrentHighlightId} />
                }
            </Grid>



        </Grid>


    )
}