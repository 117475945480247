import { Chip } from "@mui/material";
import { useState } from "react";
import { randomColorList } from "./_randomColors";

export default function ChipInformation({ idx, search, highlightIds, onClick }) {

    const randomColor = randomColorList[idx % randomColorList.length]

    return (
        <Chip label={search}
            sx={{margin:"4px", backgroundColor: '#fff'}}
            onClick={() => onClick()}
            color="primary"
            variant="outlined"
            //style={{ backgroundColor: randomColor }}
        />

    )
}