import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {Grid, Box} from '@mui/material';
import ChatbotTab from '../tabs/ChatbotGlobalTab';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box container
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{
        padding: 0, 
        height:"100%" 
      }}
      {...other}
    >
      {value === index && (
        <Grid container item
          xs={12} 
          sx={{
            padding: 0, 
            height:"100%" 
          }}
          justifyItems={'center'}
          alignItems={'stretch'}>
          {children}
        </Grid>
      )}
    </Box>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function GlobalPage() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

  return (
    <Box sx={{width: '100%', height:'100%'}}>
      <Grid container item sx={{ borderBottom: 1, borderColor: 'divider', backgroundColor:'#fff' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Chatbot" {...a11yProps(0)} />
        </Tabs>
      </Grid>
      <CustomTabPanel value={value} index={0}>
        <ChatbotTab />
      </CustomTabPanel>
    </Box>
  );
}