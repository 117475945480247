import { Autocomplete, TextField, createFilterOptions } from "@mui/material";
import { useState } from "react";

const filter = createFilterOptions();

export default function AutocompleteAdder({ value, setValue, label, options, afterOnChange = () => { } }) {


    return (
        <Autocomplete
            value={value}
            options={options}
            onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                    setValue({
                        title: newValue,
                    });
                    afterOnChange(newValue);
                } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setValue({
                        title: newValue.inputValue,
                    });
                    afterOnChange(newValue.inputValue);
                } else {
                    setValue(newValue);
                    afterOnChange(newValue);
                }
            }}
            getOptionLabel={(option) => option.title}
            style={{ width: 300, margin: 'auto' }}
            renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.title);
                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        inputValue,
                        title: `New ${label}: "${inputValue}"`,
                    });
                }

                return filtered;
            }}

        />
    );
}