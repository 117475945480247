import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Grid, Box } from '@mui/material';
import DepouillementTab from '../tabs/DepouillementTab';
import ChatbotTab from '../tabs/ChatbotSpecifiqueTab';
import { GlobalContext } from '../App';
import { CustomTabPanel, a11yProps } from './SpecifiquePage';
import VoiceListTab from '../tabs/VoiceListTab';
import VoiceToVoiceTab from '../tabs/VoiceToVoiceTab';


export default function VoicesPage() {
    const { currentTab, setCurrentTab } = React.useContext(GlobalContext)

    const handleChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    return (
        <Grid container sx={{ display: "grid", gridTemplateRows: "48px 1fr", height: "100%" }}>
            <Grid container sx={{
                borderBottom: 1,
                borderColor: 'divider',
                backgroundColor: '#fff',
            }}>
                <Tabs value={currentTab} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Voices List" {...a11yProps(0)} />
                    <Tab label="Voice To Voice" {...a11yProps(1)} />
                </Tabs>
            </Grid>
            <CustomTabPanel value={currentTab} index={0}>
                <VoiceListTab />
            </CustomTabPanel>
            <CustomTabPanel value={currentTab} index={1}>
                <VoiceToVoiceTab />
            </CustomTabPanel>
        </Grid>
    );
}