import React, { useContext, useEffect, useState } from 'react';
import { Chatbot } from '../components/chatbot';
import FilterSourcesDialog from '../components/filterSourcesDialog';
import { GlobalContext, apiUrl } from '../App';
import { Grid } from '@mui/material';


export default function Depouillement() {
  const { sources, chatbotGeneralHistory, setChatbotGeneralHistory, chatbotGeneralPassages, setChatbotGeneralPassages,
  } = useContext(GlobalContext)

  const [currentPdfList, setCurrentPdfList] = useState(sources[0]?.title || null);

    const [structuredSources, setStructuredSources] = useState({}) // {myShowName: {mySeasonName: [pdfName1, pdfName2]}}

    const [filterShowName, setFilterShowName] = useState("ALL");
    const [filterSeasonName, setFilterSeasonName] = useState("ALL");
    const [filterPdfName, setFilterPdfName] = useState("ALL");

  return (
    <Grid container item xs={12} sx={{height:"100%"}}>
        <Chatbot
          currentPdfList={currentPdfList}
          filterPdfName={filterPdfName}
          filterSeasonName={filterSeasonName}
          filterShowName={filterShowName}
          history={chatbotGeneralHistory}
          setHistory={setChatbotGeneralHistory}
          passages={chatbotGeneralPassages}
          setPassages={setChatbotGeneralPassages}
        />
      <FilterSourcesDialog 
        onSubmit={(showName, seasonName, pdfName) => {
          setFilterShowName(showName);
          setFilterSeasonName(seasonName);
          setFilterPdfName(pdfName);
        }
      }
      />
    </Grid>
 
  );
}