import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import PdfView from '../components/pdfview';
import { Button, Grid, MenuItem, Paper, Select, Stack, Box, IconButton } from '@mui/material';
import InformationPanel from '../components/InformationPanel';
import { GlobalContext, apiUrl } from '../App';
import PdfHighlighterView from '../components/pdfHighlighter';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RefreshIcon from '@mui/icons-material/Refresh';

export default function DepouillementTab() {
    const { sources, currentPdf, setCurrentPdf,
        highlights, setHighlights,
        currentHighlightId, setCurrentHighlightId,
        highlightsIndiv, setHighlightsIndiv,
        highlightsGrouped, setHighlightsGrouped,
        specificMode, setSpecificMode
    } = useContext(GlobalContext)

    const [tempId, setTempId] = useState(0)
    console.log('sources:', sources)

    const [currentHighlightDepouillementIdx, setCurrentHighlightDepouillementIdx] = useState(0);
    const [currentHighlightDepouillementName, setCurrentHighlightDepouillementName] = useState('');


    useEffect(() => {
        const fetchHighlights = async () => {
            if (currentPdf === null) {
                return;
            }
            try {
                //replace .pdf by .highlights.json
                const response = await fetch(`${apiUrl}/get_highlights/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ filename: currentPdf })
                });
                if (response.ok) {
                    const data = await response.json();
                    console.log('data:', data);
                    setHighlights(data);
                    setHighlightsIndiv(data);
                }
                else {
                    console.error('Failed to fetch highlights');
                }
            }
            catch (error) {
                console.error('Error fetching highlights:', error);
            }
        }
        fetchHighlights();
    }, [currentPdf])

    function next_highlight() {
        const curSource = sources.find(source => source.title === currentPdf);
        setCurrentHighlightId(curSource.highlights_depouillement_ids[currentHighlightDepouillementName][(currentHighlightDepouillementIdx + 1) % curSource.highlights_depouillement_ids[currentHighlightDepouillementName].length]);
        document.location.hash = `highlight-${curSource.highlights_depouillement_ids[currentHighlightDepouillementName][(currentHighlightDepouillementIdx + 1) % curSource.highlights_depouillement_ids[currentHighlightDepouillementName].length]}`;
        setCurrentHighlightDepouillementIdx((currentHighlightDepouillementIdx + 1) % curSource.highlights_depouillement_ids[currentHighlightDepouillementName].length);
    }
    function refresh_highlight() {
        document.location.hash = `highlight-${currentHighlightId}`;
    }

    function previous_highlight() {
        const curSource = sources.find(source => source.title === currentPdf);

        document.location.hash = `highlight-${curSource.highlights_depouillement_ids[currentHighlightDepouillementName][(currentHighlightDepouillementIdx - 1) % curSource.highlights_depouillement_ids[currentHighlightDepouillementName].length]}`;
        setCurrentHighlightDepouillementIdx((currentHighlightDepouillementIdx - 1) % curSource.highlights_depouillement_ids[currentHighlightDepouillementName].length);
    }

    function displayVoiceHighlight() {
        setSpecificMode('voice');
        fetch(apiUrl + 'get_highlights/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ filename: currentPdf, isVoice: true })
        }).then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                if (data.length === 0) {

                    setHighlights([{
                        id: '999999999999999',
                        position: {
                            boundingRect: {
                                x1: 0,
                                y1: 0,
                                x2: 0,
                                y2: 0,
                                pageNumber: 1,
                                width: 0,
                                height: 0,
                            },
                            pageNumber: 1,
                            rects: [
                                {
                                    x1: 0,
                                    y1: 0,
                                    x2: 0,
                                    y2: 0,
                                    width: 0,
                                    height: 0,
                                    pageNumber: 1,
                                }
                            ],
                            content: { text: 'a' }
                        }
                    }]);
                }
                else {
                    setHighlights(data);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    function displayGroups() {

        console.log('highlights:', highlights)
        let groups = []
        // add to groups all the highlights with id > "1000"
        for (let i = 0; i < highlights.length; i++) {
            if (parseInt(highlights[i].id) >= 1000) {
                groups.push(...highlights[i].position.rects);
            }
        }

        const newId = highlights[highlights.length - 1].id + 1;
        const groupedHighligh = {
            id: newId,
            position: {
                boundingRect: groups[0],
                rects: groups,
                pageNumber: groups[0].pageNumber,
            }
        }
        console.log('groupedHighligh:', groupedHighligh)
        setHighlightsGrouped([groupedHighligh]);
        setHighlights([groupedHighligh]);
        // setHighlights((prevHighlights) => [...prevHighlights, groupedHighligh]);

        // pause for 1 sec
        setTimeout(() => {
            setCurrentHighlightId(newId);
            setSpecificMode('grouped');
        }, 1000);
    }

    useEffect(() => {
        if (specificMode === 'grouped') {
            console.log('setting background to grouped')

            changeHighlightPartBackground("#ffe28f");
            // document.documentElement.style.setProperty('--highlight-background', '#ffeeee');

        }
        else if (specificMode === 'indiv') {
            console.log('setting background to indiv')
            changeHighlightPartBackground("#ffe28f00")
            setHighlights(highlightsIndiv);
        }
    }, [specificMode])




    const changeHighlightPartBackground = (color) => {
        // Accessing document styleSheets
        const styleSheets = document.styleSheets;

        // Iterating over all stylesheets
        for (let i = 0; i < styleSheets.length; i++) {
            // Accessing the rules of the current stylesheet
            const rules = styleSheets[i].cssRules || styleSheets[i].rules;

            for (let j = 0; j < rules.length; j++) {
                // If the current rule is .Highlight__part
                if (rules[j].selectorText === '.Highlight__part') {
                    // Update the background property and make it important
                    rules[j].style.background = color;
                    rules[j].style.setProperty('background', color, 'important');
                    break;
                }
            }
        }
    };


    return (
        <Grid container>
            <Grid container item xs={6} sx={{
                backgroundColor: "#fff",
                maxHeight: "100%"
            }}>
                <Grid container sx={{ maxHeight: '100%', display: "grid", gridTemplateRows: "1fr 56px" }}>
                    <Grid container sx={{
                        position: 'relative',
                    }}>
                        {currentPdf && highlights.length > 0 &&
                            <PdfHighlighterView
                                currentPdf={currentPdf}
                                highlights={highlights}
                                setHighlights={setHighlights}
                            />
                        }
                    </Grid>
                    <Grid container
                        sx={{ borderTop: 1, borderColor: 'divider' }}
                        alignItems="center"
                        justifyContent="space-between">
                        <Grid item>
                            <span>
                                {sources.length > 0 &&
                                    <Select
                                        value={currentPdf}
                                        label="PDF"
                                        onChange={(e) => setCurrentPdf(e.target.value)}
                                        style={{ marginRight: '10px' }}
                                    >
                                        {sources.map((source, index) => {

                                            return (
                                                <MenuItem key={index} value={source.title}>{source.title.slice(0, 25) + '...'
                                                }</MenuItem>
                                            )

                                        }
                                        )}
                                    </Select>
                                }
                                {sources.length === 0 &&
                                    <div>
                                        <p>loading pdfs</p>
                                    </div>
                                }
                            </span>
                        </Grid>
                        <Grid item alignItems="center">
                            {currentHighlightDepouillementName !== '' &&
                                <span sx={{ position: "relative" }}>
                                    {currentHighlightDepouillementIdx + 1}/{sources.find(source => source.title === currentPdf).highlights_depouillement_ids[currentHighlightDepouillementName].length}
                                </span>
                            }
                            <IconButton onClick={() => {
                                previous_highlight()
                            }}
                                color="primary"
                                aria-label="Précédent"
                                disabled={currentHighlightDepouillementName === ''}
                                title="Précédent"
                            >
                                <KeyboardArrowUpIcon />
                            </IconButton>
                            <IconButton onClick={() => {
                                next_highlight()
                            }}
                                color="primary"
                                aria-label="Suivant"
                                disabled={currentHighlightDepouillementName === ''}
                                title="Suivant"
                            >
                                <KeyboardArrowDownIcon />
                            </IconButton>
                            <IconButton onClick={() => {
                                refresh_highlight()
                            }}
                                color="primary"
                                aria-label="Rafraichîr"
                                disabled={currentHighlightId == null}
                                title="Rafraichîr"
                            >
                                <RefreshIcon />
                            </IconButton>
                            <Button variant="contained" onClick={displayGroups}>
                                All Tags
                            </Button>
                            <Button variant="contained" onClick={displayVoiceHighlight}>
                                Mode Voix
                            </Button>

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item xs={6}>
                <Grid container sx={{ p: 4, boxSizing: "border-box", overflow: 'auto', gridTemplateRows: "1fr" }}>
                    {currentPdf && <InformationPanel
                        data={
                            // find in sources the source with title = currentPdf and get first element
                            sources.find(source => source.title === currentPdf)
                        }
                        setCurrentHighlightIdx={setCurrentHighlightDepouillementIdx}
                        setCurrentHighlightName={setCurrentHighlightDepouillementName}
                        setCurrentHighlightId={setCurrentHighlightId}

                    />}
                </Grid>
            </Grid>
        </Grid >
    );
}