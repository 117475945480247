import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Grid, Box } from '@mui/material';
import DepouillementTab from '../tabs/DepouillementTab';
import ChatbotTab from '../tabs/ChatbotSpecifiqueTab';
import { GlobalContext } from '../App';

export function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      item
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{
        padding: 0,
      }}
      {...other}
    >
      {value === index && (
        <Grid container item
          xs={12}
          sx={{
            padding: 0,
            boxSizing: 'border-box',
            maxHeight: "100%",
            height: "100%",
            //overflow: "auto" 
          }}
          justifyItems={'center'}
          alignItems={'stretch'}>
          {children}
        </Grid>
      )}
    </Box>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function SpecifiquePage() {
  const { currentTab, setCurrentTab } = React.useContext(GlobalContext)

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Grid container sx={{ display: "grid", gridTemplateRows: "48px 1fr", height: "100%" }}>
      <Grid container sx={{
        borderBottom: 1,
        borderColor: 'divider',
        backgroundColor: '#fff',
      }}>
        <Tabs value={currentTab} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Dépouillement" {...a11yProps(0)} />
          <Tab label="Chatbot" {...a11yProps(1)} />
        </Tabs>
      </Grid>
      <CustomTabPanel value={currentTab} index={0}>
        <DepouillementTab />
      </CustomTabPanel>
      <CustomTabPanel value={currentTab} index={1}>
        <ChatbotTab />
      </CustomTabPanel>
    </Grid>
  );
}