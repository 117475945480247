import './App.css';
import { createContext, useEffect, useState } from 'react';
import { pdfjs } from 'react-pdf';
import Layout from './pages/Layout';
import GlobalPage from './pages/GlobalPage';
import GestionPage from './pages/GestionPage';
import SpecificPage from './pages/SpecifiquePage';
import { createBrowserRouter, RouterProvider, } from "react-router-dom";
import VoicesPage from './pages/VoicesPage';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export const GlobalContext = createContext()

export const apiUrl = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:5000/' : 'https://api.zephir.spirit-prod.com/';

function App() {
  const [sources, setSources] = useState([])
  const [currentTab, setCurrentTab] = useState(0) // gestion tab
  const [currentPdf, setCurrentPdf] = useState(sources[0]?.title)
  const [chatbotGeneralHistory, setChatbotGeneralHistory] = useState([{
    text: "Bonjour, comment puis-je vous aider ?",
    title: "Chatbot"
  }])
  const [chatbotGeneralPassages, setChatbotGeneralPassages] = useState([])

  const [chatbotSpecificHistory, setChatbotSpecificHistory] = useState([{
    text: "Bonjour, comment puis-je vous aider ?",
    title: "Chatbot"
  }])
  const [chatbotSpecificPassages, setChatbotSpecificPassages] = useState([])

  const [highlights, setHighlights] = useState([])
  const [highlightsIndiv, setHighlightsIndiv] = useState([])
  const [highlightsGrouped, setHighlightsGrouped] = useState([])



  const [currentHighlightId, setCurrentHighlightId] = useState(null);
  const [specificMode, setSpecificMode] = useState('indiv'); // indiv highlight or grouped highlight

  const [voicesData, setVoicesData] = useState([]) // [{character: '...', emotion: '...', voice_path:}]
  const [rvcModels, setRvcModels] = useState([]) // [{model_name: '...', model_path: '...', model_id: '...'}]

  // when currentHighlightId is updated we want to upate the hash
  useEffect(() => {
    console.log('currentHighlightId:', currentHighlightId)
    document.location.hash = `highlight-${currentHighlightId}`
  }, [currentHighlightId])


  useEffect(() => {
    fetch(apiUrl + '/get_all_voices/')
      .then(response => response.json())
      .then(data => {
        console.log('data:', data);
        setVoicesData(data);
      }
      );
  }, []);

  useEffect(() => {
    fetch(apiUrl + '/get_all_rvc_models/')
      .then(response => response.json())
      .then(data => {
        console.log('data:', data);
        setRvcModels(data);
      }
      );
  }, []);




  useEffect(() => {
    const fetchSources = async () => {
      try {
        const response = await fetch(apiUrl + 'get_sources/');
        if (response.ok) {
          const data = await response.json();
          setSources(data);
          console.log('setted sources:', data);
        } else {
          console.error('Failed to fetch sources');
        }
      }
      catch (error) {
        console.error('Error fetching sources:', error);
      }
    }
    fetchSources();
  }, [])

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "home",
          element: <GlobalPage />,
        },
        {
          path: "scenario",
          element: <SpecificPage />,
        },
        {
          path: "gestion",
          element: <GestionPage />,
        },
        {
          path: "voices",
          element: <VoicesPage />,
        }
      ],
    },
  ]);

  return (
    <GlobalContext.Provider value={{
      sources, setSources,
      currentTab, setCurrentTab,
      currentPdf, setCurrentPdf,
      chatbotGeneralHistory, setChatbotGeneralHistory,
      chatbotGeneralPassages, setChatbotGeneralPassages,
      chatbotSpecificHistory, setChatbotSpecificHistory,
      chatbotSpecificPassages, setChatbotSpecificPassages,
      highlights, setHighlights,
      currentHighlightId, setCurrentHighlightId,
      highlightsIndiv, setHighlightsIndiv,
      highlightsGrouped, setHighlightsGrouped,
      specificMode, setSpecificMode,
      voicesData, setVoicesData,
      rvcModels, setRvcModels,

    }}>
      <RouterProvider router={router}></RouterProvider>
    </GlobalContext.Provider>
  );
}

export default App;
