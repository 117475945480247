import React, { Component, useContext, useEffect, useRef, useState } from "react";
// import style
import "../styles/Highlight.css";
import {
    PdfLoader,
    PdfHighlighter,
    Tip,
    Highlight,
    Popup,
    AreaHighlight,
} from "react-pdf-highlighter";
import { CircularProgress, rgbToHex } from "@mui/material";
import { GlobalContext, apiUrl } from "../App";
import { setHighlighBackground, setHighlightSelectedBackground } from "../tabs/SpecifiqueTab";
import VoiceTip from "./voiceTip";
import VoicePopup from "./voicePopup";

export default function PdfHighlighterView({ currentPdf, highlights, setHighlights }) {

    const { setCurrentHighlightId, voicesData, rvcModels } = useContext(GlobalContext)

    let currentEventListeners = null;
    const scrollRef = useRef(() => { });

    // fetch highlights from api /uploads/<pdfname>.highlights.json

    const parseIdFromHash = () => {
        return document.location.hash.slice("#highlight-".length);
    }
    const resetHash = () => {
        console.log('resetting hash')
        document.location.hash = "";
    };

    const getHighlightById = (id) => {
        if (!highlights) {
            console.log('no highlights')
            return null;
        }
        return highlights.find((highlight) => highlight.id == parseInt(id));
    };
    const getNextId = () => String(Math.random()).slice(2);



    const scrollToHighlightFromHash = () => {
        const highlight = getHighlightById(parseIdFromHash());
        if (highlight) {
            scrollRef.current(highlight);
            // console.log('scrolling to highlight:', highlight.content.text)
        }
    };


    // 1 sec after loading, update the background color


    useEffect(() => {
        // scrollToHighlightFromHash();
        // remove previous hash change listener
        if (currentEventListeners) {
            window.removeEventListener("hashchange", currentEventListeners, false);
        }
        currentEventListeners = scrollToHighlightFromHash;
        window.addEventListener("hashchange", currentEventListeners, false);
    }, [highlights]);

    function addSpeech(mode, character, emotion, rvcModelId, audioBlob, content, position, comment) {
        console.log({ content, position, comment });
        console.log('add speech current pdf:', currentPdf)
        const formData = new FormData();
        if (mode === 'v2v') {
            formData.append('audio', audioBlob, 'recording.wav');
        }
        formData.append('pdfName', currentPdf);
        formData.append('text', content);
        formData.append('character', character);
        formData.append('emotion', emotion);
        formData.append('mode', mode);
        formData.append('rvcModelId', rvcModelId);
        formData.append('highlight', JSON.stringify({
            content,
            position,
            comment,
        }));

        console.log('formData:', formData)

        // send to api
        fetch(apiUrl + 'generate_speech/', {
            method: 'POST',
            body: formData,

        }).then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                const voiceUrl = data.voiceUrl;


                setHighlights((highlights) => [
                    ...highlights,
                    {
                        id: getNextId(),
                        content,
                        position,
                        comment,
                        voiceUrl,
                    },
                ]);

            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }



    return (
        <div
        /*style={{
            height: "90%",
            width: "45vw",
            position: "relative",
        }}
        */
        >
            {highlights.length > 0 &&
                <PdfLoader url={apiUrl + 'uploads/' + currentPdf} beforeLoad={<CircularProgress />}>
                    {(pdfDocument) => (

                        <PdfHighlighter
                            pdfDocument={pdfDocument}
                            enableAreaSelection={false}
                            // pdfScaleValue="page-width"
                            scrollRef={(scrollTo) => {
                                scrollRef.current = scrollTo;
                                const highlight = getHighlightById(parseIdFromHash())
                                if (highlight) {
                                    scrollTo(highlight);
                                }
                            }}
                            onScrollChange={resetHash}
                            onSelectionFinished={(
                                position,
                                content,
                                hideTipAndSelection,
                                transformSelection
                            ) => (
                                <VoiceTip
                                    voicesData={voicesData}
                                    content={content}
                                    onOpen={transformSelection}
                                    onConfirm={(mode, character, emotion, text, rvcModelId, audioBlob, comment) => {
                                        addSpeech(mode, character, emotion, rvcModelId, audioBlob, text, position, comment);
                                        hideTipAndSelection();
                                    }}
                                    rvcModels={rvcModels}
                                />
                            )}

                            classeName={"my-pdf-viewer"}
                            // style={{classeName {background - color: rgba(0,0,0,0); } }}


                            highlightTransform={(
                                highlight,
                                index,
                                setTip,
                                hideTip,
                                viewportToScaled,
                                screenshot,
                                isScrolledTo
                            ) => {
                                const isTextHighlight = true;
                                const component = isTextHighlight ? (
                                    <Highlight
                                        isScrolledTo={isScrolledTo}
                                        position={highlight.position}
                                        comment={highlight.comment}

                                        onClick={() => {
                                            console.log('highlight clicked with id:', highlight.id)
                                        }}
                                    />
                                ) : (
                                    <AreaHighlight
                                        isScrolledTo={isScrolledTo}
                                        highlight={highlight}

                                        onChange={(boundingRect) => {
                                            console.log('highlight changed with id:', highlight.id)
                                            this.updateHighlight(
                                                highlight.id,
                                                { boundingRect: viewportToScaled(boundingRect) },
                                                { image: screenshot(boundingRect) }
                                            );
                                        }}
                                    />
                                );


                                // const audio = new Audio("http://127.0.0.1:5000/uploads/Personnage%202_Emotion%201.mp3")
                                const audio = new Audio(highlight.voiceUrl)

                                return (
                                    <VoicePopup
                                        popupContent={null}
                                        key={index}
                                        children={component}
                                        onMouseClick={(e) => {
                                            audio.play()
                                        }}

                                        style={{
                                            background: "#ff8f8f"

                                        }}
                                    />
                                );
                            }}
                            highlights={highlights}
                        />
                    )}
                </PdfLoader>
            }
        </div >
    );
}

