import React from "react"
import { GlobalContext } from "../App"
import { useLocation, useNavigate } from "react-router-dom"

export default function PassagesPanel({ passages, setCurrentHighlightId }) {
    // get currentTab, setCurrentTab from context
    const { currentTab, setCurrentTab, setCurrentPdf, setCurrentHighlightDepouillementName,
        specificMode, setSpecificMode
    } = React.useContext(GlobalContext)
    console.log(setCurrentHighlightId)

    const location = useLocation();
    const { hash, pathname, search } = location;

    const navigate = useNavigate();


    function handleClick(passage) {
        if (pathname === '/scenario') { //specific page
            // setCurrentPdf(passage.source)

            setCurrentPdf(passage.source)
            setCurrentTab(0);
            console.debug("Tab changed to " + 0);
            setSpecificMode('indiv')
            setCurrentHighlightId(passage.bboxes_ids)


        }
        if (pathname === '/home') { //global page
            // go to specific page
            setCurrentPdf(passage.source)
            setCurrentTab(0);
            setSpecificMode('indiv')
            setCurrentHighlightId(passage.bboxes_ids)

            navigate('/scenario');
        }

        // document.location.hash = `highlight-${passage.bboxes_ids}`
    }


    return (
        <div style={{ border: "1px solid black", padding: "10px", margin: "10px" }}>
            <h2>Passages</h2>
            <ul style={{ textAlign: "start" }}>
                {passages.map((passage, index) => (
                    <li key={index} onClick={() => {
                        handleClick(passage)
                    }}
                        style={{ cursor: "pointer" }}>
                        {passage.text}
                    </li>
                ))}
            </ul>

        </div >
    )
}