import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, TextField, Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';
import { VisuallyHiddenInput } from "../components/importVoicePanel";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import React from "react";
import { apiUrl, GlobalContext } from "../App";

export default function VoiceToVoiceTab() {
    const { rvcModels } = React.useContext(GlobalContext)

    const [trainingData, setTrainingData] = React.useState({ 'model_name': '', 'wav_upload': null });
    const [loading, setLoading] = React.useState(false);
    console.log("rvcModels", rvcModels);

    return (
        <Grid container>
            <Grid
                item xs={12}
                padding={5}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                    overflow: 'auto',
                }}
            >
                <h1>Entrainer un nouveau modèle</h1>
                {/* Model Name */}
                <TextField
                    id="model_name"
                    label="Nom du modèle"
                    variant="outlined"
                    onChange={(e) => {
                        setTrainingData({ ...trainingData, 'model_name': e.target.value });
                    }}
                />

                <Button variant="outlined" color="primary"
                    component="label"
                    startIcon={<CloudUploadIcon />}
                >
                    Upload 15 min input voice
                    <VisuallyHiddenInput type="file" onChange={
                        (e) => {
                            setTrainingData({ ...trainingData, 'wav_upload': e.target.files[0] });
                        }
                    } />
                </Button>

                {trainingData.wav_upload && <p>{trainingData.wav_upload.name}</p>}

                <Button variant="contained" color="primary"
                    disabled={trainingData.model_name === '' || trainingData.wav_upload === null}
                    onClick={() => {
                        console.log('AAAAAAAAAAAa')
                        console.log(trainingData);
                        setLoading(true);
                        const formData = new FormData();
                        formData.append('model_name', trainingData.model_name);
                        formData.append('wav_upload', trainingData.wav_upload);
                        fetch(apiUrl + '/train_v2v/', {
                            method: 'POST',
                            body: formData,
                        }).then(response => {
                            if (response.ok) {
                                alert('Model successfully trained');
                            } else {
                                alert('Error training model');
                            }
                            setLoading(false);
                        }
                        );
                    }}
                >
                    Train model
                </Button>
                {loading && <p>Loading...</p>}


                <h1> Models List </h1>
                <TableContainer component={Paper} elevation={3}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}><b>Model Name</b></TableCell>
                                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}><b>Training Data Filename</b></TableCell>
                                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}><b>Status</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rvcModels.map((model) => (
                                <TableRow key={model.id}>
                                    <TableCell>{model.name}</TableCell>
                                    <TableCell>{model.training_data_filename}</TableCell>
                                    <TableCell>
                                        {model.status === 'trained' ? <CheckIcon color="success" /> : <CircularProgress size={20} />}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>


            </Grid>
        </Grid >
    );
}