import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Fab from "@mui/material/Fab";
import FilterListIcon from '@mui/icons-material/FilterList';
import { GlobalContext, apiUrl} from '../App';
import { CircularProgress, Grid, MenuItem, Select } from '@mui/material';

export default function FilterSourcesDialog({onSubmit}) {
  const [open, setOpen] = useState(false);
  const { sources,
  } = useContext(GlobalContext)
  // "mypdf.pdf" including .pdf
  const [currentPdfList, setCurrentPdfList] = useState(sources[0]?.title || null);

  const [structuredSources, setStructuredSources] = useState({}) // {myShowName: {mySeasonName: [pdfName1, pdfName2]}}

  const [filterShowName, setFilterShowName] = useState("ALL");
  const [filterSeasonName, setFilterSeasonName] = useState("ALL");
  const [filterPdfName, setFilterPdfName] = useState("ALL");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

    // fetch get_all_structured_sources
    useEffect(() => {
      const fetchStructuredSources = async () => {
          try {
              const response = await fetch(apiUrl + 'get_all_structured_sources/');
              if (response.ok) {
                  const data = await response.json();
                  setStructuredSources(data);
                  console.log('setted structured sources:', data);
              } else {
                  console.error('Failed to fetch structured sources');
              }
          }
          catch (error) {
              console.error('Error fetching structured sources:', error);
          }
      }
      fetchStructuredSources();
  }, [])

  return (
    <React.Fragment>
        <Fab 
          sx={{position:'absolute', bottom:'3em', right:'3em'}}
          color="primary" 
          aria-label="Filtrer les scénarios" 
          onClick={handleClickOpen}
        >
            <FilterListIcon />
        </Fab>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
              console.log(email);
                onSubmit(filterShowName, filterSeasonName, filterPdfName);
            handleClose();
          },
        }}
      >
        <DialogTitle>Filtrer les scénarios</DialogTitle>
        <DialogContent>
            {sources && sources.length === 0 && (
                <div>
                    <p>loading pdfs</p>
                    <CircularProgress />
                </div>
            )}
            {Object.keys(structuredSources).length > 0 && (
                <div>

                    <p>Filter by Show Name</p>
                    <Select
                        value={filterShowName}
                        onChange={(e) => setFilterShowName(e.target.value)}
                    >
                        <MenuItem value={'ALL'}>ALL</MenuItem>
                        {Object.keys(structuredSources).map(showName => (
                            <MenuItem value={showName}>{showName}</MenuItem>
                        ))}
                    </Select>

                    <p>Filter by Season Name</p>
                    <Select
                        value={filterSeasonName}
                        onChange={(e) => setFilterSeasonName(e.target.value)}
                    >
                        <MenuItem value={'ALL'}>ALL</MenuItem>

                        {Object.keys(structuredSources).map(showName => Object.keys(structuredSources[showName])).flat().map(seasonName => (
                            <MenuItem value={seasonName}>{seasonName}</MenuItem>
                        ))}
                    </Select>

                        

                    <p>Filter by PDF Name</p>
                    <Select
                        value={filterPdfName}
                        onChange={(e) => setFilterPdfName(e.target.value)}
                    >
                        <MenuItem value={'ALL'}>ALL</MenuItem>

                        {Object.values(structuredSources).flatMap(show =>
                            Object.values(show).flatMap(season => season)
                        ).map(pdfName => (
                            <MenuItem value={pdfName}>{pdfName}</MenuItem>
                        ))}
                    </Select>

                    <p>PDF List</p>
                    <ul>
                        {/* case everythin all */}
                        {filterPdfName === 'ALL' && filterSeasonName === 'ALL' && filterShowName === 'ALL' && 
                            Object.keys(structuredSources).map(show =>
                                Object.keys(structuredSources[show]).map(season =>
                                    structuredSources[show][season].map(pdfName =>
                                        <li>{pdfName}</li>
                                    )
                                )
                        )
                        }
                        {/* case showName selected */}
                        {filterShowName !== 'ALL' && filterSeasonName === 'ALL' && filterPdfName === 'ALL' && 
                            Object.keys(structuredSources[filterShowName]).map(season =>
                                Object.values(structuredSources[filterShowName][season]).map(pdfName =>
                                <li>{pdfName}</li>
                                )
                            )
                        }
                        {/* case showName and seasonName selected */}
                        {filterShowName !== 'ALL' && filterSeasonName !== 'ALL' && filterPdfName === 'ALL' && 
                            structuredSources[filterShowName][filterSeasonName].map(pdfName =>
                                <li>{pdfName}</li>
                            )
                        }
                        {/* case showName and seasonName and pdfName selected */}
                        {filterShowName !== 'ALL' && filterSeasonName !== 'ALL' && filterPdfName !== 'ALL' && 
                            <li>{filterPdfName}</li>
                        }
                        {/* case only pdfName is selected */}


                    </ul>
                </div>
            )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button color="primary" type="submit">Valider</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}