import React, { Component } from "react";
import { MouseMonitor } from "react-pdf-highlighter";
class VoicePopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mouseIn: false,
        };
    }

    render() {
        const { onMouseOver, onMouseClick, popupContent, onMouseOut, children } = this.props;

        return (
            <div
                // onMouseOver={() => {
                //     this.setState({ mouseIn: true });

                //     onMouseOver(
                //         <MouseMonitor
                //             onMoveAway={() => {
                //                 if (this.state.mouseIn) {
                //                     return;
                //                 }

                //                 onMouseOut();
                //             }}
                //             paddingX={60}
                //             paddingY={30}
                //             children={popupContent}
                //         />
                //     );
                // }}
                onClick={() => {
                    onMouseClick();
                }}
                onMouseOut={() => {
                    this.setState({ mouseIn: false });
                }}
            >
                {children}
            </div>
        );
    }
}

export default VoicePopup;
