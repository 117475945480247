import { Autocomplete, Button, MenuItem, Select, Stack, TextField, createFilterOptions } from "@mui/material";
import ImportPDFButton from "./importPdfButton";
import { useEffect, useState } from "react";
import AutocompleteAdder from "./autocompleteAdder";
import { apiUrl } from "../App";

export default function ImportPDFPanel() {

    const [valueShowName, setValueShowName] = useState(null);
    const [valueSeasonName, setValueSeasonName] = useState(null);
    
    const [optionsShowName, setOptionsShowName] = useState([]);
    const [optionsSeasonName, setOptionsSeasonName] = useState([]);

    const [dummy, setDummy] = useState("");

    //function to fetch /get_show_names/ from backend
    const getShowNames = async () => {
        const response = await fetch(apiUrl + '/get_show_names/');
        const data = await response.json();
        return data;
    }

    //function to fetch /get_season_names/ from backend
    const getSeasonNames = async (showName) => {
        const response = await fetch(apiUrl + '/get_season_names/' + showName);
        const data = await response.json();
        return data;
    }

    const afterOnChangeShowName = (newValue) => {
        getSeasonNames(newValue.title).then(data => {
            console.log('season_names:', data);
            setOptionsSeasonName(data);
        });
    }

    useEffect(() => {
        getShowNames().then(data => {
            console.log('show_names:', data);
            setOptionsShowName(data);
        });
    }, []);

    return (
        <div>
            {/* orizontal stack */}
            <Stack direction="row" spacing={2}>

                <AutocompleteAdder value={valueShowName}
                    setValue={setValueShowName}
                    label='Show Name'
                    options={optionsShowName}
                    afterOnChange={afterOnChangeShowName}
                />
                <AutocompleteAdder
                    value={valueSeasonName}
                    setValue={setValueSeasonName}
                    label='Season Name'
                    options={optionsSeasonName}
                />
            </Stack>
            <ImportPDFButton
                valueShowName={valueShowName}
                valueSeasonName={valueSeasonName}
            />
        </div>
    );
}