import React, { useContext, useState, useRef } from 'react';
import { GlobalContext, apiUrl } from '../App';
import { Button, CircularProgress, Input } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

function ImportPDFButton({ valueShowName, valueSeasonName }) {
    // State to hold the selected file
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);

    const { setSources } = useContext(GlobalContext)

    const [loading, setLoading] = useState(false);

    // Handle file selection

    const handleFileChange = (event) => {
        console.log(event.target.files[0]);
        setSelectedFile(event.target.files[0]);
    };

    // Handle file upload
    const handleUpload = async () => {
        if (!selectedFile) {
            alert('Please select a file first!');
            return;
        }

        console.log('valueShowName:', valueShowName.title);
        console.log('valueSeasonName:', valueSeasonName);

        const formData = new FormData();
        formData.append('show_name', valueShowName.title);
        formData.append('season_name', valueSeasonName.title);
        formData.append('file', selectedFile);

        try {
            setLoading(true);
            const response = await fetch(apiUrl + 'upload_pdf/', {

                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                alert('File successfully uploaded');
                // setSource to the response data
                const data = await response.json();
                setSources(data);
                setLoading(false);
            } else {
                alert('Failed to upload file');
            }
        } catch (error) {
            setLoading(false);
            console.error('Error uploading file:', error);
            alert('Error uploading file');
        }
    };

    const handleButtonClick = (event) => {
        event.preventDefault();
        fileInputRef.current.click();
    };

    return (
        <div>
            <br />
            <Button variant="outlined" color="primary"
                component="label"
                startIcon={<CloudUploadIcon />}
            >
                Choisir un fichier
                <VisuallyHiddenInput type="file" onChange={handleFileChange} />
            </Button>
            {selectedFile && <p>{selectedFile.name}</p>}
            <br />
            <br />
            <br />
            {/* center button */}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <br />
                <Button variant="contained" onClick={handleUpload}
                    disabled={loading}
                >
                    {loading ? <CircularProgress /> : 'Upload'}
                </Button>

                <div>
                </div>
            </div>
        </div>
    );
}

export default ImportPDFButton;
