import { Chip, Divider, Grid, Box } from "@mui/material"
import ChipInformation from "./chipInformation";
import { useContext, useState } from "react";
import { GlobalContext } from "../App";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

export default function InformationPanel({ data, setCurrentHighlightIdx, setCurrentHighlightName, setCurrentHighlightId }) {
    console.log(data)

    const { specificMode, setSpecificMode } = useContext(GlobalContext)

    const displayKeysList = ['characters', 'assets', 'decors']

    // const [currentHighlightIdx, setCurrentHighlightIdx] = useState(0);
    // const [currentHighlightName, setCurrentHighlightName] = useState('');

    function onNewTagClick(tag) {
        if (specificMode !== 'indiv') {
            setSpecificMode('indiv')
        }
        setCurrentHighlightIdx(0);
        setCurrentHighlightName(tag);
        setCurrentHighlightId(data.highlights_depouillement_ids[tag][0]);
    }



    return (
        // textallign left
        <Grid container item sx={{
            overflow: 'auto',
            height: '100%'
        }}
            justifyContent='center'
        >
            <Grid item>
                <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                        {/*
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                Show - Ep. XXX - Nom de l'épisode
                            </Typography>
                        */}
                        <Typography variant="h5" component="div">
                            Résumé
                        </Typography>
                        <Typography variant="body2">
                            {data.depouillement.resume}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid container item>
                {
                    displayKeysList.map((key, index) => {

                        let currentLabel = key.charAt(0).toUpperCase() + key.slice(1)
                        if (currentLabel === 'Assets') {
                            currentLabel = 'Props'
                        }

                        return (

                            <Box key={index} sx={{ width: "100%" }}>
                                <Divider sx={{ padding: "2em" }}>
                                    <Chip label={currentLabel} size="small" />
                                </Divider>
                                {data.depouillement[key].map((search, index) => {
                                    return (
                                        <ChipInformation key={index} idx={index} search={search} highlightIds={data.highlights_depouillement_ids[search]}
                                            onClick={() => onNewTagClick(search)}
                                        />
                                    )
                                })}
                            </Box>
                        )
                    })}
            </Grid>

        </Grid >
    )
}