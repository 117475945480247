export const randomColorList = ['#A1A6F0',
    '#C0888D',
    '#8C808B',
    '#C2ED8B',
    '#DBBC9E',
    '#EBDAC0',
    '#BBC3A4',
    '#80E8CA',
    '#FFF6C2',
    '#CCB3B6',
    '#FD85EA',
    '#FBFE9D',
    '#8195FA',
    '#B4A9D6',
    '#A3DBE0',
    '#ABE1F5',
    '#C698E9',
    '#81DBD8',
    '#C6F3EA',
    '#F4A292',
    '#90B1E7',
    '#F8D486',
    '#C9F4AA',
    '#A0D59B',
    '#D3CCBF',
    '#BBB78E',
    '#BAFEAF',
    '#B2D392',
    '#EDCFD7',
    '#9BE6F5',
    '#80FA9A',
    '#9AF68F',
    '#B6F99B',
    '#88D4B4',
    '#80CFA1',
    '#928F80',
    '#808E8B',
    '#9AED86',
    '#8196A9',
    '#C9C7C4',
    '#BABD96',
    '#F6D69C',
    '#B4A6D6',
    '#F2B691',
    '#FBBD97',
    '#EFE6F5',
    '#ED868C',
    '#FA85D0',
    '#DCCF87',
    '#90B4A8',
    '#F9D4C6',
    '#F8F3E2',
    '#FAEDE5',
    '#A2B7E5',
    '#E791BD',
    '#C687E3',
    '#D1AE8B',
    '#F89BDF',
    '#DAE7A1',
    '#D3E0C8',
    '#898BB1',
    '#F2B4CD',
    '#B0D783',
    '#83998F',
    '#F1C6BC',
    '#FFE2C8',
    '#B4F3BD',
    '#F7EADD',
    '#DADD9C',
    '#C2AFE6',
    '#BF83C9',
    '#ABCBDF',
    '#9EB1F5',
    '#FE84BA',
    '#D1C6FA',
    '#BDACEE',
    '#C0D996',
    '#8CB5DB',
    '#CDD1BD',
    '#CE8AEA',
    '#928C91',
    '#B88C96',
    '#A69AC4',
    '#A1FD8C',
    '#D9F585',
    '#DE9BD9',
    '#E5CFE7',
    '#80A6C0',
    '#EE968B',
    '#C7B1AE',
    '#99E0EA',
    '#F4ECBB',
    '#ACEFD4',
    '#83E1EA',
    '#9285D4',
    '#8FFDEA',
    '#F4B08D',
    '#BFEFE4',
    '#FD8194',
    '#D487DA',
    '#C7E08A',
    '#BCDFA2',
    '#CDC9D0',
    '#ADD5C6',
    '#F1C2DA',
    '#BAEED4',
    '#AA91B8',
    '#B7D3C9',
    '#DDED92',
    '#BA84FB',
    '#E7E48C',
    '#B09EF8',
    '#E4E4AF',
    '#A5FD8A',
    '#BD90B9',
    '#B199FF',
    '#F6D48F',
    '#F1E28B',
    '#D1FDA4',
    '#B99FFE',
    '#E4CA95',
    '#A7C6FB',
    '#F7BD93',
    '#9DDB95',
    '#FF94FD',
    '#F0CDAA',
    '#B2868D',
    '#9182DC',
    '#85AEE1',
    '#B5FDC8',
    '#C097C8',
    '#CDAEB2',
    '#E88EFA',
    '#98CA87',
    '#86F7AF',
    '#C9E68B',
    '#9EF5AD',
    '#B187EF',
    '#F6C8E6',
    '#FBDBA2',
    '#A5C488',
    '#D084B0',
    '#C0A1FA',
    '#9ABF80',
    '#C6B6F0',
    '#DFD1CE',
    '#D2848D',
    '#BA95FF',
    '#A589EF',
    '#918FD9',
    '#8598A3',
    '#B2E9CB',
    '#F9DD96',
    '#CA93C4',
    '#E888D3',
    '#E7978D',
    '#C6CCA8',
    '#DF9EB9',
    '#AAB98C',
    '#A1AB98',
    '#B8A487',
    '#D797B5',
    '#B7C8B3',
    '#A4A9B7',
    '#A8D2A7',
    '#E7AAFA',
    '#FCCFFF',
    '#C9D5CC',
    '#ABC0B3',
    '#FBD88E',
    '#D0CF87',
    '#DE8EDE',
    '#E49096',
    '#86AFAB',
    '#E6E2FC',
    '#D291CF',
    '#EAE7B0',
    '#F0EFC4',
    '#F3B0C5',
    '#C1B2E2',
    '#9BB684',
    '#F9E5A2',
    '#849EA8',
    '#95C1B0',
    '#AED7BD',
    '#F8B187',
    '#E9BFD4',
    '#CE9286',
    '#F1DB9E',
    '#88AFDA',
    '#FDAFEA',
    '#91AAF8',
    '#A4D581',
    '#8C9AB6',
    '#84D994',
    '#E9D788',
    '#F3FDCF',
    '#FAEDF5',
    '#E28EB2',
    '#A194B4']
