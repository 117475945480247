import React, { useContext, useEffect, useState } from 'react';
import { Chatbot } from '../components/chatbot';
import { GlobalContext, apiUrl } from '../App';
import { Grid } from '@mui/material';


export default function Depouillement() {
  const { sources, currentPdf, setCurrentPdf,
      chatbotSpecificHistory, setChatbotSpecificHistory,
      chatbotSpecificPassages, setChatbotSpecificPassages,
  } = useContext(GlobalContext)

  console.log("=== ChatBot");
  console.log(currentPdf);

  return (
    <Grid container item xs={12}>
        <Chatbot
          filterPdfName={currentPdf}
          filterSeasonName={"ALL"}
          filterShowName={"ALL"}
          history={chatbotSpecificHistory}
          setHistory={setChatbotSpecificHistory}
          passages={chatbotSpecificPassages}
          setPassages={setChatbotSpecificPassages}
      />
    </Grid>
 
  );
}