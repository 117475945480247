import { Button, Paper, Stack, styled, Tab, Tabs } from "@mui/material";
import React, { useEffect } from "react";
import AutocompleteAdder from "./autocompleteAdder";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { apiUrl } from "../App";
import { a11yProps, CustomTabPanel } from "../pages/SpecifiquePage";
import NewRvcVoice from "./newRvcVoice";

export const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function ImportVoicePanel() {

    const [newVoiceUpload, setNewVoiceUpload] = React.useState(null);
    const [newVoiceCharacter, setNewVoiceCharacter] = React.useState(null);
    const [newVoiceEmotion, setNewVoiceEmotion] = React.useState(null);

    const [elevenVoice, setElevenVoice] = React.useState(null);

    const [optionsVoiceCharacter, setOptionsVoiceCharacter] = React.useState([]);
    const [optionsVoiceEmotion, setOptionsVoiceEmotion] = React.useState([]);
    const [optionsElevenVoices, setOptionsElevenVoices] = React.useState([]);

    const [currentTab, setCurrentTab] = React.useState(0);

    const [voiceMode, setVoiceMode] = React.useState(0); // 0 for elevenlabs, 1 for xtts, 2 for rvc

    // at startup fetch /get_all_voices/ from backend
    useEffect(() => {
        fetch(apiUrl + '/get_all_voices/')
            .then(response => response.json())
            .then(data => {
                const unique_characters = [...new Set(data.map(item => item.character))];
                const unique_emotions = [...new Set(data.map(item => item.emotion))];
                setOptionsVoiceCharacter(
                    unique_characters.map(character => ({ title: character }))
                );
                setOptionsVoiceEmotion(
                    unique_emotions.map(emotion => ({ title: emotion }))
                );
            });
        fetch(apiUrl + '/get_all_elevenlabs_voices/')
            .then(response => response.json())
            .then(data => {
                setOptionsElevenVoices(data.map(voice => ({ title: voice })));
            });
    }, []);




    const handleFileChange = (event) => {
        setNewVoiceUpload(event.target.files[0]); // Set the first selected file
    };
    const handleSubmit = async () => {
        if (!newVoiceCharacter || !newVoiceEmotion) {
            alert("Please fill in all fields and upload a file.");
            return;
        }

        console.log("elevenvoice:", elevenVoice);
        console.log("newVoiceCharacter:", newVoiceCharacter);
        console.log("newVoiceEmotion:", newVoiceEmotion);
        console.log("newVoiceUpload:", newVoiceUpload);

        const formData = new FormData();
        const form_eleven_voice = elevenVoice ? elevenVoice.title : null;
        const form_voice_upload = newVoiceUpload ? newVoiceUpload : null;
        formData.append("elevenVoice", form_eleven_voice);
        formData.append("character", newVoiceCharacter.title);
        formData.append("emotion", newVoiceEmotion.title);
        formData.append("voiceUpload", form_voice_upload);
        formData.append("voiceMode", currentTab);

        fetch(apiUrl + '/add_new_voice/', {
            method: 'POST',
            body: formData,
            headers: {
                'Accept': 'application/json',
            }
        }).then(response => {
            if (response.ok) {
                alert('Voice successfully uploaded');
            } else {
                alert('Error uploading voice');
            }
        }
        );

    };

    return (
        <Paper>
            <Tabs value={currentTab} onChange={(event, newValue) => setCurrentTab(newValue)}
                aria-label="basic tabs example">
                <Tab label="Elevenlabs Voice" {...a11yProps(0)} />
                <Tab label="XTTS Voice" {...a11yProps(1)} />
                <Tab label="RVC Voice" {...a11yProps(1)} />
            </Tabs>

            <CustomTabPanel value={currentTab} index={0}>

                <Stack direction="column" spacing={2}>
                    <AutocompleteAdder
                        value={newVoiceCharacter}
                        setValue={setNewVoiceCharacter}
                        label="Personnage"
                        options={optionsVoiceCharacter}
                    />
                    <AutocompleteAdder
                        value={newVoiceEmotion}
                        setValue={setNewVoiceEmotion}
                        label="Emotion"
                        options={optionsVoiceEmotion}
                    />

                    <br />
                    <AutocompleteAdder
                        value={elevenVoice}
                        setValue={setElevenVoice}
                        label="Eleven Voice name"
                        options={optionsElevenVoices}
                    />


                    <Button variant="contained" color="primary"
                        onClick={handleSubmit}
                    >
                        Valider
                    </Button>




                </Stack>
            </CustomTabPanel>

            <CustomTabPanel value={currentTab} index={1}>
                <Stack direction="column" spacing={2}>
                    <AutocompleteAdder
                        value={newVoiceCharacter}
                        setValue={setNewVoiceCharacter}
                        label="Personnage"
                        options={optionsVoiceCharacter}
                    />
                    <AutocompleteAdder
                        value={newVoiceEmotion}
                        setValue={setNewVoiceEmotion}
                        label="Emotion"
                        options={optionsVoiceEmotion}
                    />

                    <br />

                    <Button variant="outlined" color="primary"
                        component="label"
                        startIcon={<CloudUploadIcon />}
                    >
                        Upload xtts reference voice
                        <VisuallyHiddenInput type="file" onChange={handleFileChange} />
                    </Button>

                    {newVoiceUpload && <p>{newVoiceUpload.name}</p>}

                    <Button variant="contained" color="primary"
                        onClick={handleSubmit}
                    >
                        Valider
                    </Button>
                </Stack>
            </CustomTabPanel>

            <CustomTabPanel value={currentTab} index={2}>
                <Stack direction="column" spacing={2}>
                    <AutocompleteAdder
                        value={newVoiceCharacter}
                        setValue={setNewVoiceCharacter}
                        label="Personnage"
                        options={optionsVoiceCharacter}
                    />
                    <AutocompleteAdder
                        value={newVoiceEmotion}
                        setValue={setNewVoiceEmotion}
                        label="Emotion"
                        options={optionsVoiceEmotion}
                    />
                    <Button variant="outlined" color="primary"
                        component="label"
                        startIcon={<CloudUploadIcon />}
                    >
                        Upload 15 min input voice
                        <VisuallyHiddenInput type="file" onChange={handleFileChange} />
                    </Button>

                    {newVoiceUpload && <p>{newVoiceUpload.name}</p>}
                    <Button variant="contained" color="primary"
                        onClick={handleSubmit}
                    >
                        Valider
                    </Button>
                </Stack>

            </CustomTabPanel>

        </Paper >
    )
}